<template>
  <div class="my-course-card" @click="courseCardOnClick">
    <div class="card-title">
      <div class="class-time">
        {{ getDayWeekMonth(courseSettingValue) }}
      </div>
      <div v-if="detail.lastDateTime" class="latest-issue">最近一期：{{ detail.lastDateTime.slice(0, 16) }}</div>
    </div>
    <div class="image-info">
      <div class="class-image">
        <img v-if="detail.coursePictureUrlList[0]" class="img" :src="detail.coursePictureUrlList[0]" />
        <img v-else class="img" src="../img/kctp_zw.png" />
      </div>
      <div class="title-info">
        <div class="title-text">{{ detail.courseName }}</div>
        <div class="info-item">主理人：{{ detail.teacher }}</div>
        <div class="info-item">当前报名：{{ detail.applyUserCount }}人</div>
        <div class="info-item">课程介绍：{{ detail.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { dayWeekMonthMap, getDayWeekMonth } from '../map.js';

export default {
  name: 'myCourseCard',
  props: {
    detail: {
      type: Object,
      default: () => ({
        courseSettingList: [],
        coursePictureUrlList: []
      })
    }
  },
  data() {
    return {
      dayWeekMonthMap,
      getDayWeekMonth
    };
  },
  computed: {
    courseSettingValue() {
      const list = Array.isArray(this.detail.courseSettingList) ? this.detail.courseSettingList : [];
      return list[0] || {};
    }
  },
  created() {},
  methods: {
    courseCardOnClick() {
      this.$router.push({
        name: 'myCourseDetail',
        query: {
          id: this.detail.id
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.my-course-card {
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.1);
  border-radius: 16px;
  padding: 16px 18px;
  .card-title {
    display: flex;
    align-items: center;
    .class-time {
      font-size: 24px;
      line-height: 28px;
      color: rgba(0, 0, 0, 0.85);
    }
    .latest-issue {
      height: 40px;
      font-size: 24px;
      line-height: 32px;
      color: #ffffff;
      background-color: #367bf4;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      margin-left: 20px;
      padding: 0px 30px;
    }
  }
  .image-info {
    margin-top: 20px;
    box-sizing: border-box;
    display: flex;
    .class-image {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 250px;
      height: 246px;
      border-radius: 16px;
      overflow: hidden;
      margin-right: 24px;
      .img {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
      }
    }
    .title-info {
      width: calc(100% - 274px);
      .title-text {
        font-size: 32px;
        line-height: 36px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.85);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .info-item {
        font-size: 24px;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.85);
        margin-top: 15px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    }
  }
}
</style>
