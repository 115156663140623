<template>
  <van-tabbar class="v-tabbar" safe-area-inset-bottom v-model="tabbarActive" @change="tabbarOnChange">
    <van-tabbar-item>
      <span>所有课程</span>
      <template #icon="props">
        <img :src="props.active ? iconMap.sykc_active : iconMap.sykc" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item>
      <span>我的课程</span>
      <template #icon="props">
        <img :src="props.active ? iconMap.wdkc_active : iconMap.wdkc" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import Vue from 'vue';
import { Tabbar, TabbarItem } from 'vant';
import { iconMap } from '../map.js';
Vue.use(Tabbar);
Vue.use(TabbarItem);

export default {
  name: 'vTabbar',
  props: {
    active: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      tabbarActive: 0,
      iconMap,
      routePath: {
        0: '/futureEdu/nightSchool/allCourseList',
        1: '/futureEdu/nightSchool/myCourseSheetList'
      }
    };
  },
  created() {
    this.tabbarActive = this.active;
  },
  methods: {
    tabbarOnChange(active) {
      this.$router.push(this.routePath[active]);
    }
  }
};
</script>

<style lang="less" scoped></style>
